var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "textarea-container" },
    [
      _c(
        "v-textarea",
        _vm._g(
          _vm._b(
            {
              staticClass: "textarea-input",
              attrs: { outlined: "" },
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue"
              }
            },
            "v-textarea",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.inputValue,
              expression: "!inputValue"
            }
          ],
          staticClass: "textarea-placeholder"
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import sendRequest from "../../app/utils/send-request-helper";

export const getQuestionImage = (questionType) => {
  let baseUrl =
    "https://inqli-prod-images.s3.ca-central-1.amazonaws.com/app/patterns";
  const questionTypeWithImage = {
    instruction: "person",
    advice: "faces",
    coaching: "planes",
    "iac-all": "hands",
  };
  // get random color from 1 to 4
  const randomColor = Math.floor(Math.random() * 4) + 1;
  return `${baseUrl}/${questionTypeWithImage[questionType]}${randomColor}.jpg`;
};

export const getQuestionReplies = async (questionId) => {
  const replyList = await sendRequest({
    url: `/resources/replies?question_id=${questionId}`,
    method: "GET",
  });
  return replyList;
};

export const getQuestionTagList = async () => {
  const tagList = await sendRequest({
    url: `/resources/skills`,
    method: "GET",
    isAuth: true,
  });
  return tagList.map((tag) => tag.value);
};

export const createQuestion = async (question, tags) => {
  const body = {
    ...question,
    image_url: getQuestionImage(question.type),
    open: true,
    added_tags: tags,
  };
  const response = await sendRequest({
    url: `/resources/questions`,
    method: "POST",
    isAuth: true,
    body,
  });
  return response;
};

export const likeQuestion = async ({ userId, questionId }) => {
  const response = await sendRequest({
    url: `/relationships/liked?source_id=${userId}&dest_id=${questionId}`,
    method: "POST",
  });
  return response;
};

export const unlikeQuestion = async ({ userId, questionId }) => {
  const response = await sendRequest({
    url: `/relationships/liked?source_id=${userId}&dest_id=${questionId}`,
    method: "DELETE",
  });
  return response;
};

export const bookmarkQuestion = async ({ userId, questionId }) => {
  const response = await sendRequest({
    url: `/relationships/bookmarked?source_id=${userId}&dest_id=${questionId}`,
    method: "POST",
  });
  return response;
};

export const unBookmarkQuestion = async ({ userId, questionId }) => {
  const response = await sendRequest({
    url: `/relationships/bookmarked?source_id=${userId}&dest_id=${questionId}`,
    method: "DELETE",
  });
  return response;
};

/**
 * Get user replied, authored and liked questions
 */
export const getUserQuestion = async ({
  userId,
  relationship,
  limit,
  currentPage,
}) => {
  const response = await sendRequest({
    url: `/resources/questions?user_id=${userId}&relationship=${relationship}&limit=${limit}&page=${currentPage}`,
    method: "GET",
  });
  return response;
};

export const getQuestionDetails = async (questionId) => {
  const data = await sendRequest({
    url: `/pages/question-details/${questionId}`,
    method: "GET",
  });
  return data;
};

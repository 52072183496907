<template>
  <div class="textarea-container">
    <v-textarea
      outlined
      v-model="inputValue"
      class="textarea-input"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </v-textarea>
    <div class="textarea-placeholder" v-show="!inputValue">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "TextArea",
  props: ["value"],
  inheritAttrs: false,
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.textarea-container {
  position: relative;
  .textarea-placeholder {
    font-size: 14px;
    color: lighten($inqliDarkGray, 10%);
    line-height: 1.8;
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 2px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    // TODO make the height the same with textarea-input
  }
  .textarea-input {
    border-radius: 8px;
    resize: vertical;
    background-color: transparent;
    z-index: 1;
    margin-bottom: 2px;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn-toggle",
    _vm._b(
      {
        model: {
          value: _vm.toggleValue,
          callback: function($$v) {
            _vm.toggleValue = $$v
          },
          expression: "toggleValue"
        }
      },
      "v-btn-toggle",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.options, function(item, index) {
      return _c(
        "v-btn",
        {
          key: index,
          class: _vm.buttonClasses(item.value),
          attrs: { plain: "", height: "35px", value: item.value }
        },
        [
          _vm._v(" " + _vm._s(item.label) + " "),
          item.badge
            ? _c(
                "v-chip",
                {
                  staticClass: "ml-1 button-badge",
                  attrs: {
                    "x-small": "",
                    dark: "",
                    pill: "",
                    color: item.badge.color
                  }
                },
                [_vm._v(" " + _vm._s(item.badge.text))]
              )
            : _vm._e()
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
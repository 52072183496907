<template>
  <v-btn-toggle v-model="toggleValue" v-bind="$attrs">
    <v-btn
      plain
      height="35px"
      v-for="(item, index) in options"
      :key="index"
      :value="item.value"
      :class="buttonClasses(item.value)"
    >
      {{ item.label }}
      <v-chip
        class="ml-1 button-badge"
        x-small
        dark
        pill
        v-if="item.badge"
        :color="item.badge.color"
      >
        {{ item.badge.text }}</v-chip
      >
    </v-btn>
  </v-btn-toggle>
</template>
<script>
export default {
  name: "ToggleButtonGroup",
  inheritAttrs: false,
  props: {
    value: {
      default: undefined,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    // this should be use mx-{number} - defined by vuetify style class
    spaceBetween: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClasses() {
      return (itemValue) => {
        const baseClasses = {
          "toggle-button-item": true,
          "toggle-button-item-inactive": this.toggleValue !== itemValue,
          "toggle-button-item-active": this.toggleValue === itemValue,
        };
        baseClasses[this.spaceBetween] = true;
        return baseClasses;
      };
    },
    toggleValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.toggle-button-item {
  font-size: 13px;
  font-weight: 600;
}
.toggle-button-item-inactive {
  color: $inqliBlack !important;
  background-color: $inqliLightGray !important;
}
.toggle-button-item-active {
  color: $inqliPurple !important;
  background-color: transparent !important;
}
.button-badge {
  padding: 7px;
  font-size: 10px;
  font-weight: 600;
}
</style>
